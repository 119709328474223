<template>
  <div v-loading="loading" class="ehpc">
    <div class="container">
      <div v-show="showTalk" class="left">
        <div class="title">
          所有集群
        </div>
        <el-tooltip placement="right" effect="light">
          <template #content>
            <div class="slurm-hover-name">111</div>
            <div class="slurm-hover-name">222</div>
          </template>
          <div
            v-for="(item, i) in talkList"
            :key="i"
            class="list"
            :class="{ active: i === activeColor }"
            @mouseenter="enterHandle(i,item)"
            @dblclick="clickHandle(i,item)"
          >
            <svg-icon :icon-class="[i === activeColor ? 'cluster-on' : 'cluster']" />
            <span>{{ item.publicAddress }}</span>
          </div>
        </el-tooltip>
      </div>
      <div class="middle">
        <div class="control" @click="showHandle">
          <img src="@/assets/icon/arrow.png" alt="">
        </div>
      </div>
      <div class="right">
        <div class="header">
          <div class="box">
            <div class="image">
              <img src="@/assets/icon/node.png" alt="">
            </div>
            <div class="text">
              <div class="num">58</div>
              <div class="desc">节点数（个）</div>
            </div>
          </div>
          <div class="box">
            <div class="image">
              <img src="@/assets/icon/core.png" alt="">
            </div>
            <div class="text">
              <div class="num">12</div>
              <div class="desc">核心数（个）</div>
            </div>
          </div>
          <div class="box">
            <div class="image">
              <img src="@/assets/icon/job.png" alt="">
            </div>
            <div class="text">
              <div class="num">122</div>
              <div class="desc">作业数（个）</div>
            </div>
          </div>
          <div class="box">
            <div class="image">
              <img src="@/assets/icon/queue.png" alt="">
            </div>
            <div class="text">
              <div class="num">106</div>
              <div class="desc">队列数（个）</div>
            </div>
          </div>
        </div>
        <div class="form">
          <div class="form-content">
            <div class="btn">
              <el-button @click="setHandle"><svg-icon icon-class="submit" />提交</el-button>
            </div>
            <el-form ref="addForm" :rules="rules" :model="addForm" size="small">
              <div class="content">
                <div class="coloumn">
                  <el-form-item prop="jobName" label="作业名称" label-width="6em">
                    <el-input v-model="addForm.jobName" />
                  </el-form-item>
                  <el-form-item prop="jobQueue" label="作业队列" label-width="6em">
                    <el-input v-model="addForm.jobQueue" />
                  </el-form-item>
                  <el-form-item prop="process" label="节点进程数" label-width="6em">
                    <el-input-number v-model="addForm.process" :min="1" :step="1" />
                  </el-form-item>
                  <el-form-item prop="node" label="节点数" label-width="6em">
                    <el-input-number v-model="addForm.node" :min="1" :step="1" />
                  </el-form-item>
                </div>
                <div class="coloumn">
                  <el-form-item prop="dir" class="data-dir" label="数据目录" label-width="6em">
                    <el-input
                      v-model="addForm.dir"
                      readonly
                      style="cursor:pointer"
                      placeholder="点击选择数据目录"
                      @click="changDirs"
                    />
                  </el-form-item>
                  <el-form-item prop="script" label="执行脚本">
                    <el-input v-model="addForm.script" :rows="6" type="textarea" />
                  </el-form-item>
                </div>
              </div>
            </el-form>
          </div>
          <div>
            <div v-for="(item,i) in modeList" :key="i" class="mode" :class="{ actives: i === activesColor }" @click="clickModeHandle(i,item)">{{ item }}</div>
          </div>

        </div>
        <div class="list">
          <el-table
            ref="multipleTable"
            v-loading="loading"
            :data="jobList"
            tooltip-effect="dark"
            style="width: 100%; height: auto"
            :row-style="tableCellJobStyle"
            @row-click="rowJobClick"
          >
            <el-table-column prop="idJob" label="作业ID" show-overflow-tooltip />
            <el-table-column
              prop="jobName"
              label="作业名称"
              show-overflow-tooltip
            />
            <el-table-column
              prop="timeStart"
              :formatter="formatTime"
              label="运行时间"
              show-overflow-tooltip
            />
            <el-table-column prop="state" label="作业状态" show-overflow-tooltip>
              <template #default="scope">
                <div
                  v-if="scope.row.state === 'JOB_RUNNING'"
                  class="state_circles"
                  style="color: #2385ff"
                >
                  <div class="circles" style="background-color: #2385ff" />
                  <div>{{ scope.row.state }}</div>
                </div>
                <div
                  v-if="scope.row.state === 'JOB_COMPLETE'"
                  class="state_circles"
                  style="color: #15a675"
                >
                  <div class="circles" style="background-color: #15a675" />
                  <div>{{ scope.row.state }}</div>
                </div>
                <div
                  v-if="scope.row.state === 'JOB_CANCELLED'"
                  class="state_circles"
                  style="color: #fd8e3f"
                >
                  <div class="circles" style="background-color: #fd8e3f" />
                  <div>{{ scope.row.state }}</div>
                </div>
                <div
                  v-if="scope.row.state === 'JOB_FAILED'"
                  class="state_circles"
                  style="color: #ff1e1e"
                >
                  <div class="circles" style="background-color: #ff1e1e" />
                  <div>{{ scope.row.state }}</div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination">
            <div class="total">合计：{{ totalPage }}</div>
            <el-pagination
              v-model:currentPage="pageNum"
              :page-size="pageSize"
              :page-count="pagenumber"
              :page-sizes="pageSizes"
              layout="sizes"
              :total="totalPage"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
            <div class="jumper">
              <div>{{ pageNum }}/{{ pagenumber }}</div>
              <div class="jumper-line" />
              <el-pagination
                v-model:currentPage="pageNum"
                :page-size="pageSize"
                :page-count="pagenumber"
                :page-sizes="pageSizes"
                background
                layout="jumper"
                :total="totalPage"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
              />
            </div>
            <el-pagination
              v-model:currentPage="pageNum"
              :page-size="pageSize"
              :page-count="pagenumber"
              :page-sizes="pageSizes"
              background
              layout="prev, next"
              :total="totalPage"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
          </div>
        </div>
        <div class="tabs">
          <el-tabs
            v-model="editableTabsValue"
            type="card"
            closable
            @tab-remove="removeTab"
          >
            <el-tab-pane
              v-for="item in editableTabs"
              :key="item.name"
              :label="item.title"
              :name="item.name"
            >
              {{ item.content }}
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>

    <!-- 选择数据目录 -->
    <div class="dialog-container">
      <el-dialog
        v-model="dirFormVisible"
        title="选择目录"
        :close-on-click-modal="false"
        :before-close="handleClose"
        width="50%"
      >
        <div v-loading="loadingDialog" class="dialog-content">
          <div class="left">
            <el-tree
              ref="tree"
              :data="data"
              :props="defaultProps"
              icon-class="el-icon-arrow-right"
              @node-click="handleNodeClick"
            >
              <template #default="{ node }">
                <span class="custom-tree-node">
                  <span><svg-icon style="margin: 0 12px" icon-class="files" /></span>
                  <span>{{ node.label }}</span>
                </span>
              </template>
            </el-tree>
          </div>
          <div class="right">
            <el-table
              ref="multipleTable"
              :data="filesData"
              tooltip-effect="dark"
              style="width: 100%"
              :row-style="tableCellStyle"
              :row-class-name="tableRowClassName"
              @row-contextmenu="rightClick"
              @row-click="rowClick"
              @row-dblclick="rowDbClick"
              @cell-mouse-enter="mousePass"
            >
              <el-table-column prop="name" label="名称" show-overflow-tooltip />
              <!-- <el-table-column prop="size" label="大小" show-overflow-tooltip /> -->
              <el-table-column prop="size" label="大小" show-overflow-tooltip>
                <template #default="scope">
                  {{ scope.row.size }}Bytes
                </template>
              </el-table-column>
              <el-table-column prop="type" label="文件类型" show-overflow-tooltip>
                <template #default="scope">
                  <div>
                    <div v-if="scope.row.type === ''" style="display: inline-block"><svg-icon icon-class="file" /></div>
                    <div v-else-if="scope.row.type === 'cmd'" style="display: inline-block"><svg-icon icon-class="cmd" /></div>
                    <div v-else-if="scope.row.type === 'md'" style="display: inline-block"><svg-icon icon-class="md" /></div>
                    <div v-else-if="scope.row.type === 'text'" style="display: inline-block"><svg-icon icon-class="txt" /></div>
                    <div v-else-if="scope.row.type === 'zip'" style="display: inline-block"><svg-icon icon-class="zip" /></div>
                    <div v-else-if="scope.row.type === 'image'" style="display: inline-block"><svg-icon icon-class="img" /></div>
                    <div v-else><svg-icon icon-class="other" /></div>
                    <span v-show="scope.$index === currentRow" style="padding-left: 5px;color: #0949ee" @click="clickDir()">确定</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="modified"
                label="修改日期"
                :formatter="formatTime"
                show-overflow-tooltip
              />
            </el-table>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import { queryEhpcList } from '@/api/ehpc'
import {
  filesList,
  dirsList,
  queueList,
  addJobs
} from '@/api/file'
import { jobList } from '@/api/task'
import { ElMessage } from 'element-plus'
export default {
  data() {
    return {
      modeList: ['交互模式', '进阶模式'],
      // tabs
      editableTabsValue: '2',
      editableTabs: [
        {
          title: 'Tab 1',
          name: '1',
          content: ''
        },
        {
          title: 'Tab 2',
          name: '2',
          content: ''
        }
      ],
      tabIndex: 2,
      uId: '',
      token: '',
      loading: false,
      showTalk: true,
      boxShow: false,
      activeColor: 0,
      activesColor: 0,
      modeType: '',
      talkList: [],
      currentRow: -1,
      loadingDialog: false,
      dirFormVisible: false,
      filesData: [],
      tableData: [],
      data: [],
      path: '',
      queue: '',
      row: '',
      defaultProps: {
        children: 'childDir',
        label: 'name'
      },
      rules: {
        jobName: [{ required: true, trigger: 'blur', message: '请输入作业名称' }],
        jobQueue: [{ required: true, trigger: 'blur', message: '请输入' }],
        dir: [{ required: true, trigger: 'blur', message: '请选择目录' }],
        process: [{ required: true, trigger: 'change', message: '请选择节点进程数' }],
        node: [{ required: true, trigger: 'change', message: '请选择节点数' }],
        script: [{ required: true, trigger: 'blur', message: '请输入执行脚本' }]
      },
      addForm: {
        jobName: '',
        jobQueue: '',
        script: '',
        dir: '',
        process: 1,
        node: 0
      },
      rowJob: '',
      jobList: [],
      pageSize: 4,
      pageNum: 1,
      pagenumber: 0,
      totalPage: 0,
      pageSizes: [4, 8, 16, 20]
    }
  },
  mounted() {
    this.uId = store.getters.uId
    this.getSession()
    this.getDirsList()
    this.getFiles()
    this.directory = '/public/home/' + store.getters.name
    this.path = this.directory + '/'
    this.getData()
    // const that = this
    // this.timer=setInterval( () => {
    //   jobList(that.pageNum, that.pageSize, that.search)
    //     .then((response) => {
    //       that.jobList = response.data.content;
    //       that.pagenumber = response.data.totalpages;
    //       that.totalPage = response.data.totalelements;
    //     })
    // }, 5000)
  },
  // beforeUnmount() {
  //   clearInterval(this.timer)
  //   this.timer = ''
  // },
  methods: {
    removeTab(targetName) {
      const tabs = this.editableTabs
      let activeName = this.editableTabsValue
      if (activeName === targetName) {
        tabs.forEach((tab, index) => {
          if (tab.name === targetName) {
            const nextTab = tabs[index + 1] || tabs[index - 1]
            if (nextTab) {
              activeName = nextTab.name
            }
          }
        })
      }

      this.editableTabsValue = activeName
      this.editableTabs = tabs.filter((tab) => tab.name !== targetName)
    },
    // 作业
    rowJobClick(row) {
      console.log(row)
      this.rowJob = row
    },
    tableCellJobStyle(row) {
      console.log(row)
      if (this.rowJob === row.row) {
        return 'background-color:#f5f6fa;'
      } else {
        return 'background-color:#fff;'
      }
    },
    // 分页
    handleSizeChange(val) {
      this.pageSize = val
      this.pageNum = 1
      this.getData()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getData()
    },
    getData() {
      this.loading = true
      jobList(this.pageNum, this.pageSize, '')
        .then((response) => {
          this.jobList = response.data.content
          this.pagenumber = response.data.totalpages
          this.totalPage = response.data.totalelements
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 时间格式
    // 时间格式化
    formatTime(row, column) {
      const data = row[column.property]
      const dtime = new Date(data)
      const year = dtime.getFullYear()
      let month = dtime.getMonth() + 1
      if (month < 10) {
        month = '0' + month
      }
      let day = dtime.getDate()
      if (day < 10) {
        day = '0' + day
      }
      let hour = dtime.getHours()
      if (hour < 10) {
        hour = '0' + hour
      }
      let minute = dtime.getMinutes()
      if (minute < 10) {
        minute = '0' + minute
      }
      let second = dtime.getSeconds()
      if (second < 10) {
        second = '0' + second
      }
      return (
        year +
        '-' +
        month +
        '-' +
        day +
        ' ' +
        hour +
        ':' +
        minute +
        ':' +
        second
      )
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex
    },
    mousePass(row, column, cell, event) {
      this.currentRow = row.index
    },
    clickDir() {
      this.dirFormVisible = false
    },
    changDirs() {
      this.dirFormVisible = true
    },
    getDirsList() {
      this.loadingDialog = true
      dirsList(this.path).then((response) => {
        // console.log(JSON.stringify(response))
        // this.data = this.getTreeData(response.data)
        const data = [
          { name: response.data.name, path: response.data.path, childDir: [] }
        ]
        data[0].childDir = response.data.childDir
        this.data = data
        this.loadingDialog = false
      })
    },
    // 文件列表
    getFiles() {
      if (this.data.length !== 0) {
        this.loadingDialog = true
      }
      filesList(this.path)
        .then((response) => {
          if (this.data.length !== 0) {
            this.loadingDialog = false
          }

          const dirs = []
          response.data.items.forEach(item => {
            if (item.isDir) {
              dirs.push(item)
            }
          })
          this.filesData = dirs
        })
        .catch(() => {
          this.path = this.nextPath
        })
    },
    // 点击节点事件
    handleNodeClick(data) {
      this.path = data.path
      this.$nextTick(() => {
        this.getFiles()
      })
    },
    rowClick(row) {
      this.row = row
      if (this.row.isDir !== true) {
        ElMessage.warning('该数据不是目录，请选择正确的目录')
      } else {
        this.addForm.dir = this.row.path
      }
      // this.dirFormVisible = false
    },
    tableCellStyle(row) {
      console.log(row)
      if (this.row === row.row) {
        return 'background-color:#f5f6fa;'
      } else {
        return 'background-color:#fff;'
      }
    },
    // 打开新的文件夹或者下载文件
    rowDbClick(row) {
      if (row.isDir) {
        this.path = row.path
        this.getFiles()
      }
    },
    clickModeHandle(i, item) {
      this.activesColor = i
      this.modeType = item
    },
    // 点击集群列表
    clickHandle(i, item) {
      this.activeColor = i
    },
    // 鼠标移入
    enterHandle(i, item) {
      this.activeColor = i
      this.boxShow = true
    },
    showHandle() {
      this.showTalk = !this.showTalk
    },
    getSession() {
      queryEhpcList(this.uId).then((response) => {
        console.log(JSON.stringify(response))
        this.talkList = response.data.clusterList
        // this.loading = false
      })
    }
  }
}
</script>
<style lang="less" scoped>
.ehpc{
  width: 100%;
  height: 100%;
  ::v-deep .el-form-item.is-error .el-input__inner, .el-form-item.is-error .el-input__inner:focus, .el-form-item.is-error .el-textarea__inner, .el-form-item.is-error .el-textarea__inner:focus{
    border-color: #dcdfe6;
  }
  .pagination{
    ::v-deep .el-icon{
      color: #fff;
    }
  }
  ::v-deep .el-dialog{
    max-height: 500px;
    .el-dialog__body{
      max-height: 500px;
    }
  }
  .dialog-content{
    display: flex;
    font-size: 12px;
    .left {
      overflow: auto;
      min-width: 220px;
      // width: 100%;
      // height: 80%;
      max-height: calc(500px - 54.44px);
      box-sizing: border-box;
      font-weight: 500;
      font-size: 14px;
      padding: 20px;
      color: #313a46;
      border-right: 1px solid #d8d8d8;
      ::v-deep .el-tree-node.is-expanded > .el-tree-node__children {
        display: inline;
      }
      .slurm-hover-name{
        padding: 20px 90px 20px 15px;
      }
    }
    .right {
      width: 100%;
      padding: 20px;
      max-height: calc(500px - 54.44px);
      color: #202020;
      ::v-deep .el-table {
        height: 100%;
        overflow: auto;
      }
    }
  }
  .container{
    width: 100%;
  height: 100%;
    display: flex;
    .left{
    min-width: 240px;
    max-width: 400px;
    height: 100%;
    color: #313A46;
    padding-top: 20px;
    padding-left: 20px;
    .active{
      background: #F2F9FF;
      color: #2385FF;
    }
    .title{
      font-size: 16px;
font-family: PingFang SC;
font-weight: bold;
// color: #313A46;
line-height: 25px;
padding-bottom: 23px;
    }
    .list:hover{
      background: #F2F9FF;
      color: #2385FF;
    }
    .list {
      font-size: 14px;
font-family: PingFang SC;
font-weight: 500;
padding: 6px 0 6px 6px;
span {
  vertical-align: middle;
}
    }
    .svg-icon {
      margin-right: 8px;
      width: 16px;
      height: 16px;
      vertical-align: middle;
    }
  }
  .middle{
    width: 12px;
    height: 100%;
background: #F5F5F5;
border: 1px solid #E8E8E8;
padding-top: 20%;
.control{
  width: 10px;
height: 80px;
padding-top: 24px;
background: #A0E1F9;
}
  }
  .right{
    width: 100%;
    height: 100%;
    overflow: scroll;
    .tabs{
      min-width: 1060px;
      ::v-deep.el-tabs--card>.el-tabs__header .el-tabs__item{
        border-bottom: 1px solid #e4e7ed;
      }
      ::v-deep.el-tabs--card>.el-tabs__header .el-tabs__nav{
        border-bottom: 1px solid #e4e7ed;
      }
      ::v-deep.el-tabs--card>.el-tabs__header .el-tabs__item.is-active{
        border-bottom: 1px solid #e4e7ed;
      }
      ::v-deep.el-tabs__item.is-active{
        color: #313A46;
        background: #E1E1E1;
      }
    }
    .list{
      min-width: 1060px;
      padding-left: 20px;
      padding-top: 11px;
      ::v-deep .el-table--scrollable-x .el-table__body-wrapper {
        overflow-x: hidden;
      }
    }
    .header{
      display: flex;
      padding-top: 21px;
      padding-left: 20px;
      padding-bottom: 11px;
      .box{
        margin-right: 20px;
        padding: 31px 66px;
        display: flex;
        justify-content: center;
        align-items: center;
        // min-width: 250px;
        background: #FFFFFF;
        box-shadow: 0px 2px 10px 0px rgba(2, 34, 74, 0.2);
        border-radius: 2px;
        .image{
          width: 48px;
          height: 48px;
          margin-right: 18px;
          background: #FFFFFF;
          border: 1px solid #B3BCC7;
          border-radius: 50%;
          position: relative;
          img{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
          }
        }
        .text{
          .num{
            font-size: 24px;
            font-weight: bold;
            color: #2385FF;
          }
          .desc{
            font-size: 12px;
            font-weight: 400;
            color: #999999;
          }
        }
      }
    }
    .form{
      margin-left: 20px;
      display: flex;
      // min-width: 1060px;
      // width: 100%;
      .form-content{
        padding: 9px 0 29px 20px;
        background: rgba(245, 247, 250, 0.5);
        border: 1px solid #E8E8E8;
        min-width: 1030px;
        width: calc(100% - 50px);
      }
      .mode{
        width: 30px;
        // height: 80px;
        padding: 5px 0;
        text-align: center;
        background: #F5F5F5;
        border: 1px solid #E8E8E8;
        font-size: 15px;
        font-weight: 500;
        color: #313A46;
      }
      .mode:hover{
        color: #437aec;
      }
      .actives{
        color: #437aec;
      }
      .btn{
        text-align: right;
        margin-bottom: 9px;
        margin-right: 42px;
      }
      ::v-deep .el-button {
      margin-right: 10px;
      background-color: #437aec;
      color: #fff;
      padding: 10px 16px;
      border-radius: 2px;
      min-height: 34px;
    }
    .svg-icon {
      width: 15px;
      height: 15px;
      vertical-align: middle;
      margin-right: 6px;
    }
      .content{
        display: flex;
        .coloumn{
          width: 50%;
          padding-right: 42px;
          // .data-dir{
          //   ::v-deep.el-input__inner{
          //     border: none !important;
          //     background-color: transparent !important;
          //   }
          // }
        }
      }
    }
  }
  }

   ::v-deep .el-dialog{
  border-radius: 20px;
}
::v-deep .el-dialog__header{
      border-bottom: 1px solid #d8d8d8;
      background: #f5f5f5;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }
    ::v-deep .el-dialog__body{
      padding: 0;
    }
    ::v-deep .el-icon{
      color: #333;
    }
}
</style>
<style lang="less">
.left{
  .slurm-hover-name{}
  .el-popper.is-light{
    background-color: #F5F5F5;
  }
  .el-popper{
    min-width: 150px;
    font-weight: 500;
    line-height: 2;
  }
}
.tabs{
  .el-tabs--card>.el-tabs__header{
    border-bottom: none;
  }
  .el-tabs--card>.el-tabs__header .el-tabs__item{
    border-bottom: 1px solid #e4e7ed;
  }
  .el-tabs--card>.el-tabs__header .el-tabs__nav{
    border-bottom: 1px solid #e4e7ed;
  }
  .el-tabs--card>.el-tabs__header .el-tabs__item.is-active{
    border-bottom: 1px solid #e4e7ed;
  }
  .el-tabs__item.is-active{
    color: #313A46;
    background: #E1E1E1;
  }
  .el-tabs__item{
    padding: 0 50px;
  }
  .el-tabs__item:hover{
    color: #313A46;
    background: #E1E1E1;
    padding: 0 50px !important;
  }
  .el-tabs--card>.el-tabs__header .el-tabs__item.is-active.is-closable{
    padding: 0 50px !important;
  }
}
.data-dir{
  .el-input__inner{
    border: none;
    background-color: transparent;
  }
  input::-webkit-input-placeholder {
    color: #437aec;
  }
  input::-moz-input-placeholder {
    color: #437aec;
  }
  input::-ms-input-placeholder {
    color: #437aec;
  }
}
/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 12px;
}
/* 滚动槽 */
::-webkit-scrollbar-track {
  /* -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.3); */
  border-radius: 6px;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
  /* -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.5); */
}
::-webkit-scrollbar-thumb:window-inactive {
  background: #d8d8d8;
}
</style>
